.App {
  text-align: center;
}

.banner_hands {
  height: 40vmin;
  border-radius: 1rem;
}

.logo {
  height: 40vmin;
  border-radius: 1rem;
}

.images {
  display: flex;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

